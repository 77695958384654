import axios from 'axios';
import { setHeaderToken } from '../../../utils/auth';

const state = {
    user: null,
    package: null,
    socketToken: null,
    isLoggedIn: false,
};

const getters = {
    isLoggedIn (state){
        return state.isLoggedIn
    },
    user (state) {
        return state.user
    }
};

const actions = {
    migrate({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/migrate/login', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    auth_login({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('auth/login', data)
            .then(response => {
                localStorage.setItem('socket_token', response.data.data.socket_token)
                localStorage.setItem('token', response.data.data.token)
                localStorage.setItem('refresh_token', response.data.data.refresh_token) 
                localStorage.setItem('role', response.data.data.user.level) 

                commit('SET_ROLE', response.data.data.user.level) 
                commit('SET_USER', response.data.data.user) 
                commit('SET_PACKAGE', response.data.data.package_data) 
                commit('SET_SOCKET_TOKEN', response.data.data.socket_token) 

                setHeaderToken(response.data.data.token)

                resolve(response)
            })
            .catch(err => {
                commit('RESET_USER')  
                localStorage.removeItem('token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('socket_token')
                reject(err)
          })
        })
    },
    auth_register({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/auth/register', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    auth_verify_otp({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/auth/verify', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    auth_resend_otp({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/auth/resend', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    auth_reset_password({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/auth/reset-password', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    auth_verify_reset_password({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/auth/check-otp', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    auth_change_reset_password({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/auth/reset-password-otp', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    auth_login_admin({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('admin/user/' + data.id + '/login', data)
            .then(response => {
                let oldToken = localStorage.getItem('token')
                localStorage.setItem('old_token', oldToken)

                localStorage.setItem('socket_token', response.data.data.socket_token)
                localStorage.setItem('token', response.data.data.token)
                localStorage.setItem('refresh_token', response.data.data.refresh_token) 
                localStorage.setItem('role', response.data.data.user.level) 

                commit('SET_ROLE', response.data.data.user.level) 
                commit('SET_USER', response.data.data.user) 
                commit('SET_PACKAGE', response.data.data.package_data) 
                commit('SET_SOCKET_TOKEN', response.data.data.socket_token) 

                setHeaderToken(response.data.data.token)

                resolve(response)
            })
            .catch(err => {
                commit('RESET_USER')  
                localStorage.removeItem('token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('socket_token')
                reject(err)
          })
        })
    },
    auth_login_admin_back({ commit }, data) {
        return new Promise((resolve, reject) => { 
            let oldToken = localStorage.getItem('old_token')
            setHeaderToken(oldToken)

            axios.post('admin/login/back', data)
            .then(response => {
                localStorage.removeItem("old_token")

                localStorage.setItem('socket_token', response.data.data.socket_token)
                localStorage.setItem('token', response.data.data.token)
                localStorage.setItem('refresh_token', response.data.data.refresh_token) 
                localStorage.setItem('role', response.data.data.user.level) 

                commit('SET_ROLE', response.data.data.user.level) 
                commit('SET_USER', response.data.data.user) 
                commit('SET_PACKAGE', response.data.data.package_data) 
                commit('SET_SOCKET_TOKEN', response.data.data.socket_token) 

                setHeaderToken(response.data.data.token)

                resolve(response)
            })
            .catch(err => {
                commit('RESET_USER')  
                localStorage.removeItem('token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('socket_token')
                reject(err)
          })
        })
    },
    auth_login_cs({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/login', data)
            .then(response => {
                localStorage.setItem('socket_token', response.data.data.socket_token)
                localStorage.setItem('token', response.data.data.token)
                localStorage.setItem('refresh_token', response.data.data.refresh_token) 
                localStorage.setItem('role', "customer_service") 

                commit('SET_ROLE', "customer_service") 
                commit('SET_USER', response.data.data.customer_service) 
                commit('SET_SOCKET_TOKEN', response.data.data.socket_token) 

                setHeaderToken(response.data.data.token)

                resolve(response)
            })
            .catch(err => {
                commit('RESET_USER')  
                localStorage.removeItem('token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('socket_token')
                reject(err)
          })
        })
    },
    async auth_logout({commit}){
        commit('RESET_USER')  
        localStorage.removeItem('role')
        localStorage.removeItem('socket_token')
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('old_token')
    }
}

const mutations = {
    SET_USER (state, data) {
        state.user = data
        state.isLoggedIn = true
    },
    SET_PACKAGE (state, packages){
        state.package = packages
    },
    SET_SOCKET_TOKEN (state, socket_token){
        state.socketToken = socket_token
    },
    SET_ROLE (state, role) {
        state.role = role
    },
    RESET_USER (state) {
        state.user = null
        state.package = null
        state.socketToken = null
        state.isLoggedIn = false
        state.role = null
    }
};

export default {
  state,
  getters,
  actions,
  mutations
};