import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueToast from 'vue-toast-notification';
import axios from 'axios';
import VueGoodTablePlugin from 'vue-good-table';
import { setHeaderToken } from '../utils/auth'
import VModal from 'vue-js-modal'
import VuePapaParse from 'vue-papa-parse'
import JsonCSV from 'vue-json-csv'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VCalendar from 'v-calendar';
import VLoading from 'vuejs-loading-screen'
import wysiwyg from "vue-wysiwyg";

import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import html from 'highlight.js/lib/languages/xml'

import vuePlugin from "@highlightjs/vue-plugin"

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('html', html);

import 'vue-toast-notification/dist/theme-default.css';
import './assets/css/main.css'
// import './assets/css/bootstrap.css'
import './assets/css/vs2015.css'
import './assets/css/vue-multiselect.css'
import './assets/fontawesome/css/all.min.css'
import 'vue-good-table/dist/vue-good-table.css'

// Perfect Scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

// JS
import { adminTemplate } from './adminTemplate.js'

import i18n from './i18n'

i18n.locale = "id"

Vue.use(VueToast);
Vue.use(VueGoodTablePlugin);
Vue.use(VModal)
Vue.use(VuePapaParse)
Vue.use(VCalendar)
Vue.use(VLoading)
Vue.use(vuePlugin);
Vue.use(wysiwyg, {});
Vue.use(PerfectScrollbar);
// if(localStorage.getItem("socket_token")){
//   Vue.use(VueNativeSock, 'ws://localhost:9000/ws/'+localStorage.getItem("socket_token"), {
//     reconnection: true,
//     reconnectionAttempts: 5,
//     reconnectionDelay: 3000,
//     format: 'json',
//     //connectManually: true,
//   })
// }

Vue.component(VueQrcode.name, VueQrcode);
Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false

const token = localStorage.getItem('token');

if (token) { 
  setHeaderToken(token) 
} 

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

Vue.prototype.$apiURL = process.env.VUE_APP_BASE_URL
Vue.prototype.$adminTemplate = adminTemplate

var mixin = {
  data: function () {
    return {
      ws: null
    }
  }
}

document.title = "Whacenter"

new Vue({
  mixin,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
