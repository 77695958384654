import axios from 'axios';

const state = {
    customer_service: "",
    chats: [],
    pending_chat_lists: [],
    active_chat_lists: []
};

const getters = {

};

const actions = {
    customer_service_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('customer_services', { params: data })
            .then(response => {
                commit("PUSH_CS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('customer_service', data)
            .then(response => {
                commit("PUSH_CS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('customer_service/' + data.id, data)
            .then(response => {
                commit("PUSH_CS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('customer_service/' + data.id)
            .then(response => {
                commit("PUSH_CS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('customer_service/' + data.id)
            .then(response => {
                commit("PUSH_CS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_dropdown_list({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('customer_services/all')
            .then(response => {
                commit("PUSH_CS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },


    //CS
    customer_service_get_device({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/devices')
            .then(response => {
                commit("PUSH_CS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_pending_chat_lists({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/chat-lists/' + data.id + '/pending', { params: data })
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_active_chat_lists({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/chat-lists/' + data.id + '/active', { params: data })
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_chats({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/chats/' + data.id + '/' + data.number)
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_receive_chats({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/chats/' + data.id + '/' + data.number + '/receive')
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_close_chats({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/chats/' + data.id + '/' + data.number + '/close')
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_validate({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/check/' + data.id + '/' + data.number)
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    customer_service_update_category({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('cs-management/chat-lists/' + data.id, data)
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    cs_outgoing_send({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/send', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_CS(state, data){
        state.customer_service = data
    },
    PUSH_HISTORY_CHATS_CS(state, data){
        state.chats = data
    },
    PUSH_HISTORY_CHAT_LISTS_CS_PENDING(state, data){
        state.pending_chat_lists = data
    },
    PUSH_HISTORY_CHAT_LISTS_CS_ACTIVE(state, data){
        state.active_chat_lists = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};