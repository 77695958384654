<template>
  <div id="app">
    <!-- <div class="preloader">
      <div class="lds-ripple">
        <div class="lds-pos"></div>
        <div class="lds-pos"></div>
      </div>
    </div> -->

    <div >
      <div v-if="$store.state.auth.isLoggedIn && !fullscreen">
        <div id="main-wrapper">
          <Aside />
          <Header />
          <div class="page-wrapper" style="display: block; margin-top: 10px">
            <router-view />
            
            <!-- <Footer /> -->
            <!-- <footer class="footer text-center">
              CodeLabs © 2024. All Rights Reserved.
            </footer> -->
          </div>
        </div>
        <!-- <p class="text-dark">cek 1</p>
        <div class="layout-light side-menu overlayScroll">
          <div class="mobile-search"></div>
          <div class="mobile-author-actions"></div>
          <Header />
          <main class="main-content">
            <Aside />
            <router-view />
            <Footer />
          </main>
        </div> -->
        <Floating />
      </div>
      <div v-else-if="$store.state.auth.isLoggedIn && fullscreen">
        <router-view />
      </div>
      <div v-else-if="!$store.state.auth.isLoggedIn">
        <div class="layout-light side-menu main-wrapper not-log-in">
          <main class="main-content">
            <router-view />
          </main>
        </div>
      </div>
      <div v-else>
        <div class="layout-light side-menu main-wrapper not-log-in">
          <main class="main-content">
            <router-view />
          </main>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import Aside from '@/components/layouts/Aside.vue'
  import Header from '@/components/layouts/Header.vue'
  // import Footer from '@/components/layouts/Footer.vue'
  import Floating from '@/components/layouts/Floating.vue'

  export default {
    components: {
      Aside,
      Header,
      // Footer,
      Floating
    },
    watch: {
      '$route'(to) {
        if (to.meta?.fullscreen) {
          this.fullscreen = true
        } else {
          this.fullscreen = false
        }

        document.title = to.meta.title + " - Whacenter" || 'Whacenter'
        setTimeout(() => {
          this.initTemplate();
        }, 1000)
      },
      '$store.state.auth.isLoggedIn'() {
        this.initTemplate();
      },
    },
    data() {
      return {
        connection: null,
        isLoggedIn: this.$store.state.auth.isLoggedIn,
        fullscreen: false
      }
    },
    created() {

    },
    mounted() {
      this.initTemplate();
      if (localStorage.getItem('socket_token')) {
        //console.log('socket_token :'+localStorage.getItem('socket_token'))
        this.initWS()
      }

      this.$root.$on("logout", () => {
        this.isLoggedIn = false
      })

      this.$root.$on("login", () => {
        this.isLoggedIn = true
      })
    },
    methods: {
      initTemplate() {
        // var element = document.getElementById("main-wrapper");

        // this.$adminTemplate.init(element, {
        //   Theme: false, // this can be true or false ( true means dark and false means light ),
        //   Layout: 'vertical',
        //   LogoBg: 'skin5', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
        //   NavbarBg: 'skin6', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
        //   SidebarType: 'full', // You can change it full / mini-sidebar / iconbar / overlay
        //   SidebarColor: 'skin5', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
        //   SidebarPosition: true, // it can be true / false ( true means Fixed and false means absolute )
        //   HeaderPosition: true, // it can be true / false ( true means Fixed and false means absolute )
        //   BoxedLayout: false // it can be true / false ( true means Boxed and false means Fluid )
        // });

        // this.$adminTemplate.preloader();
        // this.$adminTemplate.expandLogo();
      },
      sendToWS(message) {
        this.connection.send(message)
      },
      initWS() {
        var ws = new WebSocket(process.env.VUE_APP_WS_URL)
            
        this.connection = ws
        var t = this

        this.connection.onopen = function () {
          let initRequest = {
            event: "init",
            socket_token: localStorage.getItem('socket_token')
          }
          ws.send(JSON.stringify(initRequest))
        }

        setInterval(function () {
          ws.send("ping")
        }, 14000);

        var root = this.$root
        this.connection.onmessage = function (event) {
       // this.connection.onmessage = (event) => {
          //console.log(event.data)
          root.$emit("ws", event.data)
        }

        this.connection.onclose = function () {
          setTimeout(function () {
            t.initWS();
          }, 2500);
          //console.log(event)
        }
      },
    }
  }
</script>

<style>
  .spinner {
    height: 1rem !important;
    width: 1rem !important;
  }

  .vm--modal {
    height: auto !important;
    max-height: 95% !important;
    overflow-y: auto !important;
    position: fixed !important;
  }

  @media only screen and (max-device-width: 610px) {
    .vm--modal {
      width: 94% !important;
      margin-left: 3% !important;
      margin-right: 3% !important;
    }
  }

  hr {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  /* CUSTOM */
  .btn.px-15 {
    align-items: center;
    gap: 5px;
  }

  .p-30 {
    padding: 1.875rem !important;
  }
  .mb-30, .my-30 {
    margin-bottom: 1.875rem !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .border {
    border: 1px solid #e3e6ef !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .global-shadow {
    box-shadow: 0 5px 20px rgba(146,153,184,.03137254901960784);
  }
  .radius-xl {
    border-radius: 10px;
  }

  .action-btn {
    float: inline-end !important;
  }
</style>